import React, { useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'

const MemberEventAvailable = () => {
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan yang tersedia',
      route: null
    }
  ]

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])
  return (
    <AuthLayout>
      <div>Ini Layout untuk member event yang tersedia</div>
    </AuthLayout>
  )
}

export default MemberEventAvailable