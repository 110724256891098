import React, { useState } from 'react'
import { Box, PasswordInput, Button, Group, Flex, Text, Progress, Popover } from '@mantine/core'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import PasswordRequired from '../../../components/ui/PasswordRequired'
import { updateUserPassword } from '../../../services/users'

const defaultVal = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

const formValidation = {
  oldPassword: {
    isError: false,
    message: ''
  },
  newPassword: {
    isError: false,
    message: ''
  },
  newPasswordConfirmation: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 || password === '' ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const ChangePassword = ({ onClose }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [popoverPassword, setPopoverPassword] = useState(false)
  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(formData.newPassword)

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.newPassword).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const submitPassword = async (val) => {
    setLoadingForm(true)
    const payload = {
      oldPassword: val.oldPassword,
      newPassword: val.newPassword,
      newPasswordConfirmation: val.newPasswordConfirmation,
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await updateUserPassword(payload)
      if (response) {
        setLoadingForm(false)
        onClose()
        notificationSuccess('Update Kata Sandi Berhasil', 'Anda berhasil mengupdate kata sandi anda')
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      const captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError('Update Kata Sandi Gagal', captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <Box>
      <Text fz={14} fw={600} mb={16}>Update Kata Sandi</Text>
      <Box mb="md">
        <PasswordInput
          value={formData.oldPassword}
          name="oldPassword"
          placeholder="Masukkan kata sandi lama"
          label="Masukkan Kata Sandi Lama"
          error={validationForm.oldPassword.isError ? validationForm.oldPassword.message : ''}
          onChange={(val) => handleChangeForm('oldPassword', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mb="md">
        <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
          <Popover.Target>
            <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
              <PasswordInput
                name="newPassword"
                value={formData.newPassword}
                placeholder="Masukkan kata sandi baru "
                label="Masukkan Kata Sandi Baru Anda"
                error={validationForm.newPassword.isError ? `${validationForm.newPassword.message}` : ''}
                onChange={(val) => handleChangeForm('newPassword', val.target.value)}
                withAsterisk
              />
            </Box>
          </Popover.Target>
          <Popover.Dropdown>
            <Group gap={5} grow mt="xs" mb="md">
              {progressBar}
            </Group>
            <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.newPassword.length > 7} />
            {checkPassword}
          </Popover.Dropdown>
        </Popover>
      </Box>
      <Box mb="md">
        <PasswordInput
          value={formData.newPasswordConfirmation}
          name="newPasswordConfirmation"
          placeholder="Masukkan konfirmasi kata sandi baru anda"
          label="Konfirmasi Kata Sandi Baru Anda"
          error={validationForm.newPasswordConfirmation.isError ? validationForm.newPasswordConfirmation.message : ''}
          onChange={(val) => handleChangeForm('newPasswordConfirmation', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onClose}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitPassword(formData)}>Update</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default ChangePassword