import http from '../plugins/axios'

export const getListModule = async (params) => {
  try {
    const response = await http.get(`/modules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addModule = async (payload) => {
  try {
    const response = await http.post(`/modules`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateModule = async (moduleId, payload) => {
  try {
    const response = await http.patch(`/modules/${moduleId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteModule = async (moduleId) => {
  try {
    const response = await http.delete(`/modules/${moduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadFileModule = async (moduleId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/modules/${moduleId}/file`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}