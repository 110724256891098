import http from '../plugins/axios'

export const getListRecording = async (params) => {
  try {
    const response = await http.get(`/recordings`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addRecording = async (payload) => {
  try {
    const response = await http.post(`/recordings`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateRecording = async (recordingId, payload) => {
  try {
    const response = await http.patch(`/recordings/${recordingId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteRecording = async (recordingId) => {
  try {
    const response = await http.delete(`/recordings/${recordingId}`)
    return response.data
  } catch(error) {
    throw error
  }
}