import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select, Button, Modal, ScrollArea } from '@mantine/core'
import classes from '../AdminLayout.module.css'
import { getListTask, deleteTask } from '../../../services/task'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableTask from '../../../components/pages/Task/TableTask'
import FormTask from '../../../components/pages/Task/FormTask'
import { modalDeleteData } from '../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateRoute } from '../../../store/dataRoute'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'name',
  order: 'asc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Kode',
    width: 'auto'
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Tipe',
    width: 'auto'
  },
  {
    label: 'Nilai Kelulusan',
    width: 'auto'
  },
  {
    label: 'Maksimal Pengisian',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]


const Task = () => {
  const navigate = useNavigate()
  const permissionState = useSelector(state => state.permission.access)
  const [params, setParams] = useState(defaultParameter)
  const [taskList, setTaskList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Soal',
      route: null
    }
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetTaskList = async () => {
    setLoading(true)
    try {
      const response = await getListTask(params)
      setTaskList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetTaskList()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (val) => {
    setParams((oldVal) => ({ ...oldVal, [oldVal.skip]: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    question: (val) => {
      navigate(`/task/${val.id}/question`)
    },
    delete: (val) => {
      modalDeleteData('soal', val.id, val.name, deleteTask, handleGetTaskList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionTask = permissionState['task']
    const createdPermission = permissionTask.find(val => val.alias === 'task.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah Soal</Button>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Soal</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Soal. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableTask label={labelTable} data={taskList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Soal</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormTask dataTask={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetTaskList} />
      </Modal>
    </AuthLayout>
  )
}

export default Task