import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Text, NavLink, Paper, Tooltip, ActionIcon } from '@mantine/core'
import classes from '../AdminLayout.module.css'
import { IconChevronRight } from '@tabler/icons-react'
import Layout from '../../../components/pages/Setting/Layout'
import Theme from '../../../components/pages/Setting/Theme'
import Profile from '../../../components/pages/Setting/Profile'
import ChangePassword from '../../../components/pages/Setting/ChangePassword'
import { IconPalette, IconLayout, IconUserSearch, IconRefresh, IconUserCircle, IconLockOpen } from '@tabler/icons-react'
import { syncHrisUser } from '../../../services/users'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'

const defaultMenu = [
  {
    icon: IconUserCircle,
    label: 'Pengaturan Profil',
    value: 'profile',
    component: Profile
  },
  {
    icon: IconLockOpen,
    label: 'Ubah Sandi',
    value: 'password',
    component: ChangePassword
  },
  {
    icon: IconPalette,
    label: 'Tema',
    value: 'theme',
    component: Theme
  },
  {
    icon: IconLayout,
    label: 'Tampilan',
    value: 'layout',
    component: Layout
  }
]

const Setting = () => {
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [loadingSync, setLoadingSync] = useState(false)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Setelan',
      route: null
    }
  ]

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  const mappingMenuSetting = (data) => {
    const mappingMenu = data.map((val, index) => {
      return (
        <Paper shadow="sm" radius="md" withBorder mb='md' key={index} onClick={() => setSelectedMenu(val.value)} >
          <NavLink 
            px={12} py={12} label={<Text fz={12} fw={500} >{val.label}</Text>}
            leftSection={<val.icon size="1.2rem" stroke={1.5} />}
            rightSection={<IconChevronRight size="1rem" stroke={1.5} className="mantine-rotate-rtl" />}
            styles={{ borderRadius: '10px' }}
          />
        </Paper>
      )
    })

    const syncDataHris = () => {

      const syncData = async () => {
        setLoadingSync(true)
        try {
          const response = await syncHrisUser()
          if (response.message === 'synchronization completed') {
            notificationSuccess('Sinkronisasi Data Berhasil', 'Sinkron data dengan HRIS berhasil')
          }
        } catch (error) {
          console.log(error)
          notificationError('Sinkronisasi Data Gagal', 'Sinkron data dengan HRIS gagal')
        } finally {
          setLoadingSync(false)
        }
      }

      return (
        <Paper shadow="sm" radius="md" withBorder mb='md' >
          <NavLink 
            px={12} py={12} label={<Text fz={12} fw={500} >Sinkronisasi Data HRIS</Text>}
            leftSection={<IconUserSearch size="1.2rem" stroke={1.5} />}
            rightSection={<Tooltip label="Sinkron Data"><ActionIcon size='xs' variant='transparent' loading={loadingSync}><IconRefresh size="1rem" stroke={1.5} /></ActionIcon></Tooltip>}
            styles={{ borderRadius: '10px' }}
            onClick={() => syncData()}
          />
        </Paper>
      )
    }

    return (
      <Box>
        <Text className={classes.titleLayout} mb={20}>Setelan Sistem</Text>
        <Box>
          {mappingMenu}
          {syncDataHris()}
        </Box>
      </Box>
    )
  }

  const mappingLayoutMenu = (selected) => {
    const selectedTitle = defaultMenu.find(val => val.value === selected).label
    const MenuSelected = defaultMenu.find(val => val.value === selected).component
    return (
      <Box>
        <Text className={classes.titleLayout} mb={20} tt='capitalize'>Setelan {selectedTitle}</Text>
        <Box>
          <MenuSelected onClose={() => setSelectedMenu(null)} />
        </Box>
      </Box>
      
    )
  }
  return (
    <AuthLayout>
      {selectedMenu === null ? mappingMenuSetting(defaultMenu) : mappingLayoutMenu(selectedMenu)}
    </AuthLayout>
  )
}

export default Setting