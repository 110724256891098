import http from '../plugins/axios'

export const getListCurriculum = async (params) => {
  try {
    const response = await http.get(`/curriculums`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailCurriculum = async (curriculumId) => {
  try {
    const response = await http.get(`/curriculums/${curriculumId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addCurriculum = async (payload) => {
  try {
    const response = await http.post(`/curriculums`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCurriculum = async (curriculumId, payload) => {
  try {
    const response = await http.patch(`/curriculums/${curriculumId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteCurriculum = async (curriculumId) => {
  try {
    const response = await http.delete(`/curriculums/${curriculumId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignCurriculumTask = async (curriculumId, payload) => {
  try {
    const response = await http.patch(`/curriculums/${curriculumId}/task`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}