import React from 'react'
import { Box, Group, Card, Text, Menu, ActionIcon, Tooltip, Center } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconPencil, IconInfoCircle } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionUpdate = () => {
    const permissionTask = permissionState['task']
    const updatedPermission = permissionTask.find(val => val.alias === 'task.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={14} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={11}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionDelete = () => {
    const permissionTask = permissionState['task']
    const deletePermission = permissionTask.find(val => val.alias === 'task.delete')
    if (deletePermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={14} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={11}
        >
          Hapus
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={8} pt={6} variant='transparent' size='xs'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TaskQuestList = ({ data, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)

  const mappingData = (data) => {
    if (data.length > 0) {
      const mappingQuest = data.map((val, index) => {
        const innerHtmlQuestion = { __html: val.text }
    
        const mappingMenu = () => {
          const permissionTask = permissionState['task']
          const updatedPermission = permissionTask.find(val => val.alias === 'task.update')
          const deletedPermission = permissionTask.find(val => val.alias === 'task.delete')
          if (updatedPermission === undefined && deletedPermission === undefined) {
            return (
              <Tooltip label='Anda tidak diizinkan untuk mengakses menu ini'>
                <ActionIcon ml={10} mt={6} variant='transparent' size='xs'>
                  <IconInfoCircle stroke={1.5}/>
                </ActionIcon>
              </Tooltip>
            )
          } else {
            return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
          }
        }
    
        const mappingAnswer = (answers) => {
          const indexToAlpha = (num = 1) => {
            const A = 'A'.charCodeAt(0)
            let numberToCharacter = number => {
              return String.fromCharCode(A + number);
            }
            return numberToCharacter(num)
          }
          if (answers.length > 0) {
            const remapAnswer = answers.map((val, index) => {
              return (
                <Text fz={13} fw={val.isCorrect ? '600' : '500'} c={val.isCorrect ? 'green' : ''} >{indexToAlpha(index)}. {val.text}</Text>
              )
            })
            return remapAnswer
          } else {
            return (
              <Text fz={13} fw='600'>Belum Ada Jawaban</Text>
            )
          }
        }
        return (
          <Card shadow="sm" radius="md" withBorder padding="sm" mb='md' key={index}>
            <Box>
              <Group justify="space-between">
                <Text fz={13} fw='600'>{val.order}.</Text>
                {permissionState !== null ? mappingMenu() : ''}
              </Group>
              <Box>
                <Text fz={13} fw='600'>Pertanyaan:</Text>
                <Box component='div' style={{ width: '100%' }} className="ck-content" dangerouslySetInnerHTML={innerHtmlQuestion} />
              </Box>
            </Box>
            <Box mt={6}>
              <Text fz={13} fw='600'>Jawaban:</Text>
              {mappingAnswer(val.taskAnswers)}
            </Box>
            <Box mt={8}>
              <Text fz={13} fw='600'>Umpan Balik:</Text>
              <Text fz={13} fw='600'>{val.feedback}</Text>
            </Box>
          </Card>
          
        )
      })
      return mappingQuest
    } else {
      return (
        <Center h={250}>
          <Text fz={13} c='gray.6'>"Belum Ada Pertanyaan"</Text>
        </Center>
      )
    }
  }

  return (
    <Box>
      {mappingData(data)}
    </Box>
  )
}

export default TaskQuestList