import React from 'react'
import ProtectedRoute from '../middleware/ProtectedRoute'
import { readLocalStorageValue } from '@mantine/hooks'
import { AppShell, Box, ScrollArea, Group, Burger, Flex, Avatar, Text, Menu, Button, rem, ThemeIcon } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import AppHeader from '../components/ui/AppShell/AppHeader'
import AppSidebar from '../components/ui/AppShell/AppSidebar'
import classes from './NavHeader.module.css'
import LmsLogo from '../assets/img/lms-logo.png'
import { useSelector, useDispatch } from 'react-redux'
import { authRoutes, memberRoutes } from '../routes'
import { useNavigate, useLocation } from 'react-router-dom'
import { IconChevronDown } from '@tabler/icons-react'
import { useHeadroom } from '@mantine/hooks'
import { useViewportSize } from '@mantine/hooks'
import { getImageFile } from '../services/file'
import { IconDoorExit } from '@tabler/icons-react'
import Cookies from 'universal-cookie'
import { update } from '../store/authData'
import { dataUser } from '../store/authUser'
import { logout } from '../services/authorization'
import Breadcrumb from '../components/ui/Breadcrumb'

const AuthLayout = ({ children }) => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const layoutValue = readLocalStorageValue({ key: 'lms-app-layout' })
  const themeValue = readLocalStorageValue({ key: 'lms-app-theme' })
  const { detailUser } = useSelector(state => state.user)
  const { isLoading, access } = useSelector(state => state.permission)
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true)
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure(false)
  const pinned = useHeadroom({ fixedAt: 120 })
  const { width } = useViewportSize()
  const dispatch = useDispatch()
  
  const camelToDashCase = (str) => {
    if (str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    } else {
      return str
    }
  }

  const logoutUser = async () => {
    const response = await logout()
    if (response.message === 'Logout success') {
      cookies.remove('token_account_tandeem')
      dispatch(update({ 'authorization': null, 'isLogin': false, 'isLoading': false }))
      dispatch(dataUser({ 'detailUser': null, 'isLogin': false }))
      navigate('/login')
    }
  }

  const handleLayout = (layout) => {
    if (layout === 'nav-sidebar') {
      return (
        <AppShell
          transitionDuration={700}
          transitionTimingFunction="ease"
          offsetScrollbars={false}
          header={{ height: 70 }}
          navbar={{
            width: 280,
            breakpoint: 'sm',
            collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop }
          }}
          padding='md'
          disabled={layoutValue !== 'nav-sidebar'}
        >
          <AppShell.Header>
            <AppHeader mobileToggle={toggleMobile} desktopToggle={toggleDesktop} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSidebar grow component={ScrollArea} />
          </AppShell.Navbar>
          <AppShell.Main style={{ backgroundColor: themeValue !== 'dark' ? '#f7f0f0' : '#2e2e2e', borderRadius: '12px' }}>
            <Box my={14} ml={2}>
              <Breadcrumb />
            </Box>
            <Box p={20} style={{ backgroundColor: themeValue !== 'dark' ? '#ffffff' : '#242424', borderRadius: '12px' }}>
              { children }
            </Box>
          </AppShell.Main>
        </AppShell>
      )
    } else if (layout === 'nav-header') {
      const mappingUserLogin = (data) => {
        return (
          <Menu position='bottom-end' offset={3}>
          <Menu.Target>
            <Box style={{ cursor: 'pointer' }}>
              <Flex align="center">
                <Avatar variant='light' src={data.image !== null ? getImageFile(data.image) : null} radius="xl" size="md" color="blue.6" />
              </Flex>
            </Box>
          </Menu.Target>
          <Menu.Dropdown style={{ border: '0px', padding: '0px', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
            <Box className={classes.bgHeaderDropdown} />
            <Box style={{ width: '16rem', padding: '6px' }}>
              <Box className={classes.menuMember}>
                <Avatar variant='white' src={data.image !== null ? getImageFile(data.image) : null} radius="xl" size="lg" mr={12} />
                <Box mt={8}>
                  <Text c='blue.6' fw='600' fz={12}>{data.fullName}</Text>
                  <Text c='blue.6' fz={12}>{data.role.name}</Text>
                </Box>
              </Box>
              <Box mt={80}>
                <Menu.Divider />
                <Menu.Item color='red' leftSection={<IconDoorExit size={14}/>} onClick={logoutUser}>Keluar</Menu.Item>
              </Box>
            </Box>
          </Menu.Dropdown>
        </Menu>
        )
      }

      const mappingMenuHeader = (permissionList) => {
        if (permissionList !== null) {
          let dataRoute = []
          if (detailUser !== null) {
            dataRoute = detailUser.roleAlias === 'lms-user' ? memberRoutes : authRoutes
          }
          // eslint-disable-next-line
          const renderRoute = dataRoute.map(({ name, route, icon, type, group, permission }, index) => {
            let returnRender = null
            const dataPermission = Object.keys(permissionList)
            dataPermission.push('dashboard')
            dataPermission.push('setting')
            if (detailUser.roleAlias === 'lms-user') {
              dataPermission.push('event')
            }

            const handleRoute = (e) => {
              if (type === 'route') {
                e.preventDefault()
                navigate(route)
              }
            }

            const mappingGroupMenu = (permission) => {
              const findData = dataRoute.filter(val => val.group === permission)
              if (findData.length > 0) {
                const mappingMenu = findData.map((val, index) => {
                  return (
                    <Menu.Item 
                      color={val.route === pathname ? 'blue.6' : 'gray.5'}
                      leftSection={<ThemeIcon color={val.route === pathname ? '' : 'gray.5'} variant='light' radius="md" size="md">{val.icon}</ThemeIcon>}
                      onClick={() => navigate(val.route)}
                      fw={val.route === pathname ? '600' : '0'}
                    >
                      <span style={{ textTransform: 'capitalize', fontSize: '12px'}} >{val.name}</span>
                    </Menu.Item>
                  )
                })
                return mappingMenu
              }
            }

            const isAllowed = dataPermission.includes(camelToDashCase(permission))
            if (group === null) {
              if (type === 'route') {
                returnRender = (
                  <Button variant="transparent" onClick={(e) => handleRoute(e)}>
                    <span style={{ textTransform: 'capitalize', fontSize: '12px', color: route === pathname ? '#228be6' : 'rgb(174, 176, 179)', fontWeight: route === pathname ? '600' : ''}} >{name}</span>
                  </Button>
                )
              } else if (type === 'title') {
                returnRender = (
                  <Menu trigger="hover" openDelay={100} closeDelay={400} position='bottom-start' width={220} fz={12}>
                    <Menu.Target>
                      <Button variant="transparent" rightSection={<IconChevronDown size="0.9rem" stroke={1.5} />}>
                        <span style={{ textTransform: 'capitalize', fontSize: '12px', color: 'rgb(174, 176, 179)'}} >{name}</span>
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {mappingGroupMenu(permission)}
                    </Menu.Dropdown>
                  </Menu>
                )
              }
            }
            
            if (permission) {
              if (isAllowed) {
                return returnRender
              } else {
                return null
              }
            }
          })
          return renderRoute
        }
      } 

      return (
        <AppShell 
          header={{ height: width < 850 ? 80 : 130, collapsed: !pinned }}
          offsetScrollbars={false}
          navbar={{
            width: 280,
            breakpoint: 'sm',
            collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarMobile }
          }}
          padding="md"
        >
          <AppShell.Header>
            <header className={classes.root}>
              <Box className={classes.sectionHeader}>
                <Group gap='xs' align='center'>
                  <Burger hiddenFrom='sm' color='blue.6' size='sm' onClick={toggleMobile} />
                  <img src={LmsLogo} width='50' alt='pet-logo' />
                </Group>
                <Box>
                  {isLoading ? '' : mappingUserLogin(detailUser)}
                </Box>
              </Box>
              <Group className={classes.menuHeader} gap={5} visibleFrom="sm">
                {mappingMenuHeader(access)}
              </Group>
            </header>
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSidebar grow component={ScrollArea} />
          </AppShell.Navbar>
          <AppShell.Main pt={`calc(${rem(width < 850 ? 70 : 130)} + var(--mantine-spacing-md))`}  style={{ backgroundColor: themeValue !== 'dark' ? '#f7f0f0' : '#2e2e2e', borderRadius: '12px' }}>
            <Box my={14} ml={2}>
              <Breadcrumb />
            </Box>
            <Box p={20} style={{ backgroundColor: themeValue !== 'dark' ? '#ffffff' : '#242424', borderRadius: '12px' }}>
              { children }
            </Box>
          </AppShell.Main>
        </AppShell>
      )
    }
  }
  return (
    <ProtectedRoute>
      {handleLayout(layoutValue)}
    </ProtectedRoute>
  )
}

export default AuthLayout