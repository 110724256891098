import React from 'react'
import classes from './Login.module.css'
import { Box, Button, Text, Center, Card, Image } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import LmsLogo from '../../../assets/img/lms-logo.png'
import TandeemLogo from '../../../assets/img/tandeem-logo.png'


const Login = () => {
  const domain = window.location.hostname
  const { width, height } = useViewportSize()
  const inLmsId = '703c1ee1-2261-43ed-adb1-12359639a523'

  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <Box h={height} w={width}>
          <Box>
            <Center maw={width} h={height} align="center">
              <Box mb={24}>
                <Image src={LmsLogo} h={120} mb={20} alt='pet-impactor' fit="contain" hiddenFrom='md' />
                <Text className={classes.titleHeader}>Selamat Datang</Text>
                <Text className={classes.titleHeaderSecondary} mb={12}>Learning Management System</Text>
                <Text fz='sm' c='gray.6'>Silahkan Login dengan tombol dibawah</Text>
                <Box style={{ margin: '30px 0' }}>
                  <Button 
                    component='a'
                    href={domain === 'localhost' ? `http://localhost:3001/authentication/account/appId=${inLmsId}` : `https://sso.tandeem.co.id/authentication/account/appId=${inLmsId}`}
                    fullWidth >Login</Button>
                </Box>
              </Box>
            </Center>
          </Box>
        </Box>
        <Box pos="sticky">
          <Box className={classes.authLayout} style={{ backgroundImage: `url(https://e1.pxfuel.com/desktop-wallpaper/600/901/desktop-wallpaper-35-study-hq-study-time.jpg)`, minHeight: `${height}px`, minWidth: `${width / 2}px`}}>
            <Center h={height}>
              <Card w={width / 4} h={420} className={classes.cardLogo} shadow="sm" padding="lg" radius="lg" >
                <Box ta='center' my={30}>
                  <Image src={LmsLogo} h={180} fit='contain' />
                  <Box mt={40}>
                    <Text color='white' fw='500' fz={14}>Created By:</Text>
                    <Box mt={12}>
                      <Image src={TandeemLogo} h={25} mb={10} fit='contain' />
                      <Text color='white' fw='600' fz={14}>"PT Teman Digital Bersama"</Text>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Login