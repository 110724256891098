import http from '../plugins/axios'

export const getListSchedule = async (params) => {
  try {
    const response = await http.get(`/schedules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addSchedule = async (payload) => {
  try {
    const response = await http.post(`/schedules`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateSchedule = async (scheduleId, payload) => {
  try {
    const response = await http.patch(`/schedules/${scheduleId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteSchedule = async (scheduleId) => {
  try {
    const response = await http.delete(`/schedules/${scheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailSchedule = async (scheduleId) => {
  try {
    const response = await http.get(`/schedules/${scheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadRundownSchedule = async (scheduleId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/schedules/${scheduleId}/rundown`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}