import React, { useState, useEffect } from 'react'
import { Box, TextInput, Textarea, Button, Group, Flex, FileInput } from '@mantine/core'
import { addModule, updateModule, uploadFileModule } from '../../../services/module'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { IconFileCv } from '@tabler/icons-react'
import { getImageFile } from '../../../services/file'
import { getFileFromUrl } from '../../../plugins/getFileUrl'

const defaultVal = {
  name: '',
  description: ''
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  }
}


const FormModule = ({ dataModule, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [fileModule, setFileModule] = useState(null)
  const [attachFile, setAttachFile] = useState(false)

  const submitModule = async (formModule) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formModule.name,
      description: formModule.description
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataModule === null) {
      methodFunction = addModule(payload)
      titleMessageSuccess = 'Tambah Modul Materi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan modul materi baru'
      titleMessageError = 'Gagal Menambahkan Modul Materi'
    } else {
      methodFunction = updateModule(formModule.id, payload)
      titleMessageSuccess = 'Update Modul Materi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate modul materi'
      titleMessageError = 'Gagal Mengupdate Modul Materi'
    }
    try {
      const response = await methodFunction
      if (response) {
        if (attachFile) {
          const uploadRundown = await handleUploadFileModule(response.id || formModule.id)
          if (uploadRundown.affected === 1) {
            onCloseModal()
            reloadList()
            notificationSuccess(titleMessageSuccess, captionMessageSuccess)
            setLoadingForm(false)
          }
        } else {
          onCloseModal()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUploadFileModule = async(scheduleId) => {
    const form = new FormData()
    form.append('file', fileModule)
    try {
      const response = await uploadFileModule(scheduleId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (dataModule !== null) {
      handleSetForm(dataModule)
    }
    // eslint-disable-next-line
  }, [dataModule])

  const handleSetForm = async (dataModule) => {
    const dataDetail = {
      id: dataModule.id,
      name: dataModule.name,
      description: dataModule.description
    }
    setFileModule(dataModule.file !== null ? await getFileFromUrl(getImageFile(dataModule.file), dataModule.file) : null)
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }
  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Modul Materi'
          placeholder='Masukkan nama modul materi'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='deskripsi modul materi'
          placeholder='Masukkan deskripsi modul materi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={(val) => handleChangeForm('description', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <FileInput
          leftSection={<IconFileCv size='1.2rem' stroke={1.5} />}
          value={fileModule}
          onChange={(val) => [setFileModule(val), setAttachFile(true)]}
          label="Lampiran File Modul Materi"
          placeholder="File Modul Materi"
          size='xs'
          clearable
          accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitModule(formData)}>{dataModule === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormModule