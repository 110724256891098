import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getAuthUser = createAsyncThunk('user/getAuthUser', async () => {
  const session = cookies.get('authData')
  let dataUser = null
  if (session !== undefined) {
    dataUser = session
  } else {
    dataUser = null
  }
  return dataUser
})

const authUser = createSlice({
  name: 'user',
  initialState: {
    isLoading: true,
    detailUser: null
  },
  reducers: {
    dataUser: (state, action) => {
      state.isLoading = action.payload.isLoading
      state.detailUser = action.payload.detailUser
    }
  },
  extraReducers: {
    [getAuthUser.pending]: (state) => {
      state.isLoading = true
    },
    [getAuthUser.fulfilled]: (state, action) => {
      state.isLoading = false
      state.detailUser = action.payload
      
    },
    [getAuthUser.rejected]: (state) => {
      state.isLoading = false
      state.detailUser = null
    }
  }
})

export const { dataUser } = authUser.actions
export default authUser.reducer