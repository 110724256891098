import React, { useState, useEffect } from 'react'
import { Box, TextInput, Textarea, Button, Group, Flex, Select } from '@mantine/core'
import { addTask, updateTask } from '../../../services/task'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  description: '',
  code: '',
  isChoice: 'true',
  passingScore: null,
  maxAttempt: null,
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  code: {
    isError: false,
    message: ''
  },
  isChoice: {
    isError: false,
    message: ''
  },
  passingScore: {
    isError: false,
    message: ''
  },
  maxAttempt: {
    isError: false,
    message: ''
  },
}

const defaultTypeTask = [
  {
    label: 'Pilihan Ganda',
    value: 'true',
  }
]

const FormTask = ({ dataTask, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitTask = async (formTask) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formTask.name,
      description: formTask.description,
      code: formTask.code,
      isChoice: formTask.isChoice,
      passingScore: formTask.passingScore,
      maxAttempt: formTask.maxAttempt
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    payload.isChoice = payload.isChoice === 'true' ? true : false
    payload.passingScore = Number(payload.passingScore) > 0 ? Number(payload.passingScore) : null
    payload.maxAttempt = Number(payload.maxAttempt) > 0 ? Number(payload.maxAttempt) : null
    if (dataTask === null) {
      methodFunction = addTask(payload)
      titleMessageSuccess = 'Tambah Soal Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan soal baru'
      titleMessageError = 'Gagal Menambahkan Soal'
    } else {
      methodFunction = updateTask(formTask.id, payload)
      titleMessageSuccess = 'Update Soal Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate soal'
      titleMessageError = 'Gagal Mengupdate Soal'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (dataTask !== null) {
      handleSetForm(dataTask)
    }
  }, [dataTask])

  const handleSetForm = (dataTask) => {
    const dataDetail = {
      id: dataTask.id,
      name: dataTask.name,
      description: dataTask.description,
      code: dataTask.code,
      isChoice: dataTask.isChoice ? 'true' : 'false',
      passingScore: dataTask.passingScore !== null ? dataTask.passingScore.toString() : null,
      maxAttempt: dataTask.maxAttempt !== null ? dataTask.maxAttempt.toString() : null
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Soal'
          placeholder='Masukkan nama soal'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='code'
          value={formData.code}
          label='Kode Soal'
          placeholder='Masukkan kode soal'
          error={validationForm.code.isError ? `${validationForm.code.message}` : ''}
          onChange={(val) => handleChangeForm('code', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={(val) => handleChangeForm('description', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Select
          name='isChoice'
          value={formData.isChoice}
          label='Tipe Soal'
          data={defaultTypeTask}
          error={validationForm.isChoice.isError ? `${validationForm.isChoice.message}` : ''}
          onChange={(val) => handleChangeForm('isChoice', val)}
          withAsterisk
          size='xs'
          allowDeselect={false}
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='passingScore'
          value={formData.passingScore}
          label='Nilai Minimum Kelulusan'
          placeholder='Masukkan nilai'
          description='apabila tidak ada nilai kelulusan isi dengan angka 0'
          error={validationForm.passingScore.isError ? `${validationForm.passingScore.message}` : ''}
          onChange={(val) => handleChangeForm('passingScore', val.target.value)}
          withAsterisk
          size='xs'
          type='number'
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='maxAttempt'
          value={formData.maxAttempt}
          label='Maksimal Pengerjaan Soal'
          placeholder='Masukkan nilai'
          description='apabila tidak ada maksimal pengerjaan isi dengan angka 0'
          error={validationForm.maxAttempt.isError ? `${validationForm.maxAttempt.message}` : ''}
          onChange={(val) => handleChangeForm('maxAttempt', val.target.value)}
          withAsterisk
          size='xs'
          type='number'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitTask(formData)}>{dataTask === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormTask