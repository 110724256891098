import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, Button, Center, Loader } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getDetailTask } from '../../../../../services/task'
import { getListTaskQuestion, deleteTaskQuestion } from '../../../../../services/task-question'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TaskQuestList from '../../../../../components/pages/TaskQuest/TaskQuestList'
import FormTaskQuest from '../../../../../components/pages/TaskQuest/FormTaskQuest'
import { modalDeleteData } from '../../../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { updateRoute } from '../../../../../store/dataRoute'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const TaskQuest = () => {
  const params = useParams()
  const taskId = params.id
  const defaultParameter = {
    skip: 0,
    take: 10,
    task: taskId,
  }
  const permissionState = useSelector(state => state.permission.access)
  const [parameter, setParameter] = useState(defaultParameter)
  const [detailTask, setDetailTask] = useState(null)
  const [taskQuestList, setTaskQuestList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [openForm, setOpenForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const dispatch = useDispatch()
  
  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Soal',
      route: '/task'
    },
    {
      label: 'Pertanyaan',
      route: null
    },
  ]

  const handleGetTaskQuestList = async () => {
    setLoadingTable(true)
    if (detailTask === null) {
      setLoading(true)
    }
    try {
      const response = await getListTaskQuestion(parameter)
      setTaskQuestList(response.data)
      setCount(response.count)
      if (detailTask === null) {
        handleGetDetailTask(taskId)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleGetDetailTask = async (idTask) => {
    try {
      const response = await getDetailTask(idTask)
      if (response) {
        setDetailTask(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetTaskQuestList()
    // eslint-disable-next-line
  }, [parameter])

  const handleChangePage = (val) => {
    setParameter((oldVal) => ({ ...oldVal, [oldVal.skip]: (val - 1) * 10 }))
  }

  const actions = {
    edit: (val) => {
      setOpenForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('soal', val.id, val.text, deleteTaskQuestion, handleGetTaskQuestList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseForm = () => {
    setOpenForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionTask = permissionState['task']
    const createdPermission = permissionTask.find(val => val.alias === 'task.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' onClick={() => setOpenForm(true)}>Tambah Pertanyaan</Button>
      )
    }
  }

  const layoutTaskQuest = () => {
    if (!openForm) {
      return (
        <Box>
          <Text className={classes.titleLayout} mb={20}>Manajemen Daftar Pertanyaan "{detailTask.name}"</Text>
          <Box>
            <Flex justify='flex-end' mb={40}>
              {permissionState !== null ? handleCreate() : ''}
            </Flex>
          </Box>
          <Box my={20}>
            {loadingTable ? <TableSkeleton total={4} /> : <TaskQuestList data={taskQuestList} actionMethod={handleAction} /> }
          </Box>
          <Flex justify='end'>
            <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / parameter.take) || 0} />
          </Flex>
        </Box>
      )
    } else {
      return (
        <Box>
        <Text className={classes.titleLayout} mb={20}>{detailData === null ? 'Tambah' : 'Update'} Pertanyaan</Text>
          <Box mt={20}>
            <FormTaskQuest dataQuest={detailData} closeForm={onCloseForm} reloadList={handleGetTaskQuestList} />
          </Box>
        </Box>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData /> : layoutTaskQuest()}
      </Box>
    </AuthLayout>
  )
}

export default TaskQuest