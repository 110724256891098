import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea } from '@mantine/core'
import { addRecording, updateRecording } from '../../../services/event-recording'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useParams } from 'react-router-dom'

const defaultVal = {
  name: '',
  description: '',
  file: '',
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  file: {
    isError: false,
    message: ''
  },
}


const FormEventRecording = ({ dataRecording, onCloseModal, reloadList }) => {
  const params = useParams()
  const scheduleId = params.schedule_id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitRecording = async (formRecording) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formRecording.name,
      description: formRecording.description,
      file: formRecording.file,
      scheduleId: scheduleId
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataRecording === null) {
      methodFunction = addRecording(payload)
      titleMessageSuccess = 'Tambah Rekaman Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan rekaman baru'
      titleMessageError = 'Gagal Menambahkan Rekaman'
    } else {
      methodFunction = updateRecording(formRecording.id, payload)
      titleMessageSuccess = 'Update Rekaman Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate rekaman'
      titleMessageError = 'Gagal Mengupdate Rekaman'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (dataRecording !== null) {
      handleSetForm(dataRecording)
    }
    // eslint-disable-next-line
  }, [dataRecording])

  const handleSetForm = async (dataRecording) => {
    const dataDetail = {
      id: dataRecording.id,
      name: dataRecording.name,
      description: dataRecording.description,
      file: dataRecording.file
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }
  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Rekaman'
          placeholder='Masukkan nama rekaman'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={(val) => handleChangeForm('description', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='file'
          value={formData.file}
          label='Nama URL Rekaman'
          placeholder='Masukkan URL Rekaman (Contoh: Youtube)'
          error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
          onChange={(val) => handleChangeForm('file', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitRecording(formData)}>{dataRecording === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEventRecording