import { createSlice } from "@reduxjs/toolkit"

const dataRoute = createSlice({
  name: 'route',
  initialState: {
    data: [],
  },
  reducers: {
    updateRoute: ( state, action ) => {
      state.data = action.payload.data
    }
  }
})

export const { updateRoute } = dataRoute.actions
export default dataRoute.reducer