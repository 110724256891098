import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Text } from '@mantine/core'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { updateUser } from '../../../services/users'
import { useSelector, useDispatch } from 'react-redux'
import { dataUser } from '../../../store/authUser'

const defaultVal = {
  fullName: '',
  email: ''
}

const formValidation = {
  fullName: {
    isError: false,
    message: ''
  },
  email: {
    isError: false,
    message: ''
  }
}

const Profile = ({ onClose }) => {
  const dispatch = useDispatch()
  const { detailUser } = useSelector(state => state.user)
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitProfile = async (formProfile) => {
    setLoadingForm(true)
    const payload = {
      fullName: formProfile.fullName,
      email: formProfile.email
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await updateUser(payload)
      if (response) {
        const updateData = {
          email: payload.email,
          fullName: payload.fullName,
          ...detailUser
        }
        dispatch(dataUser({ 'detailUser': updateData, 'isLoading': false }))
        setLoadingForm(false)
        onClose()
        notificationSuccess('Update Profil Berhasil', 'Anda berhasil mengupdate informasi profil')
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      const captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError('Update Profil Gagal', captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (detailUser) {
      handleSetForm(detailUser)
    }
  }, [detailUser])

  const handleSetForm = (user) => {
    const dataDetail = {
      fullName: user.fullName,
      email: user.email,
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }


  return (
    <Box>
      <Text fz={14} fw={600} mb={16}>Update Profil</Text>
      <Box mb='md'>
        <TextInput
          name='fullName'
          value={formData.fullName}
          label='Nama Lengkap'
          placeholder='Masukkan nama'
          error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
          onChange={(val) => handleChangeForm('fullName', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='email'
          value={formData.email}
          label='Email'
          placeholder='Masukkan email'
          type='email'
          error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
          onChange={(val) => handleChangeForm('email', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onClose}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitProfile(formData)}>Update</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default Profile