import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Grid, Text, Badge, Card, Skeleton, Image, Button } from '@mantine/core'
import classes from '../MemberLayout.module.css'
import { getListMemberEventSchedule } from '../../../services/member-event-schedule'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'
import { getImageFile } from '../../../services/file'
import NoImage from '../../../assets/img/no-image.webp'
import { useNavigate } from 'react-router-dom'

const MemberEvent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [dataEvent, setDataEvent] = useState([])

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan yang diikuti',
      route: null
    }
  ]

  const handleGetMemberEventList = async () => {
    setLoading(true)
    try {
      const response = await getListMemberEventSchedule()
      setDataEvent(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetMemberEventList()
    // eslint-disable-next-line
  }, [])

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{ base: 12, lg: 3 }} key={i}>
        <Card shadow='sm' padding='lg' radius='md' withBorder>
          <Card.Section>
            <Skeleton height={450} width="100%" />
          </Card.Section>
        </Card>
      </Grid.Col>
    )
  })

  const mappingDataProgram = (data) => {
    if (data.length > 0) {
      const remap = data.map((val, index) => {
        return (
          <Grid.Col span={{ base: 12, lg: 3 }} key={index}>
            <Card className={classes.programCard} shadow="sm" radius="lg" withBorder>
              <Card.Section>
                <Image h={350}  w='auto' src={val.schedule.event.image !== null ? getImageFile(val.schedule.event.image) : NoImage} />
              </Card.Section>
              <Box className={classes.contentCard}>
                <Badge variant='gradient' size='xs' mb='xs' gradient={{ from: 'violet', to: 'red', deg: 90 }}>{val.schedule.event.type}</Badge>
                <Text fw='bold' fz={14} lineClamp={2}>{val.schedule.event.name}</Text>
                <Text fw='500' fz={12} lineClamp={2}>Jadwal: {val.schedule.name}</Text>
              </Box>
              <Box m={8}>
                <Button size='xs'fullWidth onClick={() => navigate(`/member/event/schedule/${val.id}`)}>Detail</Button>
              </Box>
            </Card>
          </Grid.Col>
        )}
      )
      return remap
    } else {
      return (
        <Grid.Col span={12}>
          <Box ta='center' py={120}>
            <Text c="dark">Anda belum mengikuti pelatihan apapun</Text>
          </Box>
        </Grid.Col>
      )
    }
  }
  

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Pelatihan yang Diikuti</Text>
        <Box my={30}>
          <Grid gutter='xl' className={classes.gridProgramCard} align="center">
            {loading ? loadingData(4) : mappingDataProgram(dataEvent)}
          </Grid>
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default MemberEvent