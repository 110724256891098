import React, { useState, useEffect } from 'react'
import { Box, TextInput, Textarea, Button, Group, Flex, Switch, Input, Select, Text, Avatar, FileInput, Center, MultiSelect } from '@mantine/core'
import { addEvent, updateEvent, uploadPosterEvent, assignEventByPosition, getPositionByEvent } from '../../../services/event'
import { getListEventCategory } from '../../../services/event-category'
import { getListPosition } from '../../../services/position'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import LoadingData from '../../ui/LoadingData'
import classes from './FormEvent.module.css'
import { IconPhoto } from '@tabler/icons-react'
import { getImageFile } from '../../../services/file'

const defaultVal = {
  code: '',
  name: '',
  description: '',
  scope: 'internal',
  type: 'online',
  isScheduled: 'false',
  eventCategoryId: null,
  isActive: 'false',
  isRequired: 'false',
  positionIds: []
}

const formValidation = {
  code: {
    isError: false,
    message: ''
  },
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  type: {
    isError: false,
    message: ''
  },
  scope: {
    isError: false,
    message: ''
  },
  eventCategoryId: {
    isError: false,
    message: ''
  },
  isScheduled: {
    isError: false,
    message: ''
  },
  isRequired: {
    isError: false,
    message: ''
  },
  isActive: {
    isError: false,
    message: ''
  },
  positionIds: {
    isError: false,
    message: ''
  }
}

const validationPosterEvent = {
  poster: {
    isError: false,
    message: ''
  }
}

const defaultTypeEvent = [
  {
    label: 'Online',
    value: 'online',
  },
  {
    label: 'Offline',
    value: 'offline',
  }
]

const defaultScheduledEvent = [
  {
    label: 'Terjadwal',
    value: 'true',
  },
  {
    label: 'Tidak Terjadwal',
    value: 'false',
  }
]

const defaultEvent = [
  {
    label: 'Wajib',
    value: 'true',
  },
  {
    label: 'Opsional',
    value: 'false',
  }
]

const FormEvent = ({ dataEvent, closeForm, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [positionList, setPositionList] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [posterFile, setPosterFile] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const [imagePoster, setImagePoster] = useState(null)
  const [validationPoster, setValidationPoster ] = useState(validationPosterEvent)

  const handleGetEventCategoryList = async () => {
    setLoadingData(true)
    const params = {
      isActive: 1
    }
    try {
      const response = await getListEventCategory(params)
      const dataCategory = response.data
      const remapCategory = dataCategory.map((val) => {
        const mappingValue = {
          value: val.id,
          label: val.name
        }
        return mappingValue
      })
      setCategoryList(remapCategory)
      await handleGetPositionList()
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetPositionList = async () => {
    try {
      const response = await getListPosition()
      const dataPosition = response.data
      const remapPosition = dataPosition.map((val) => {
        const mappingValue = {
          value: val.id,
          label: val.name
        }
        return mappingValue
      })
      setPositionList(remapPosition)
      if (dataEvent !== null) {
        handlePositionByEvent(dataEvent.id)
      } else {
        setLoadingData(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePositionByEvent = async (eventId) => {
    try {
      const response = await getPositionByEvent(eventId)
      const dataPosition = response.data
      const remapPosition = dataPosition.map((val) => {
        return val.position.id
      })
      handleChangeForm('positionIds', remapPosition)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }

  const submitEvent = async (formEvent) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    if (validationPoster.poster.isError) {
      notificationError('Gambar Poster Terlalu Besar', 'Silahkan ganti gambar poster dengan ukuran kurang dari 2 Mb')
      setLoadingForm(false)
      return
    }
    const payload = {
      code: formEvent.code,
      name: formEvent.name,
      description: formEvent.description,
      scope: formEvent.scope,
      type: formEvent.type,
      eventCategoryId: formEvent.eventCategoryId,
      isScheduled: formEvent.isScheduled === 'true' ? true : false,
      isRequired: formEvent.isRequired === 'true' ? true : false,
      isActive: formEvent.isActive === 'true' ? true : false
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataEvent === null) {
      methodFunction = addEvent(payload)
      titleMessageSuccess = 'Tambah Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan pelatihan baru'
      titleMessageError = 'Gagal Menambahkan Pelatihan'
    } else {
      methodFunction = updateEvent(formEvent.id, payload)
      titleMessageSuccess = 'Update Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate pelatihan'
      titleMessageError = 'Gagal Mengupdate Pelatihan'
    }
    try {
      const response = await methodFunction
      if (response) {
        await handleAssignEventByPosition(response.id || formEvent.id)
        if (posterFile !== null) {
          const uploadRes = await handleUploadPosterEvent(response.id || formEvent.id)
          if (uploadRes.affected === 1) {
            closeForm()
            reloadList()
            notificationSuccess(titleMessageSuccess, captionMessageSuccess)
            setLoadingForm(false)
          }
        } else {
          closeForm()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleAssignEventByPosition = async (eventId) => {
    const payload = {
      eventId: eventId,
      positionIds: formData.positionIds
    }
    try {
      await assignEventByPosition(payload)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadPosterEvent = async(eventId) => {
    const form = new FormData()
    form.append('file', posterFile)
    try {
      const response = await uploadPosterEvent(eventId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetEventCategoryList()
    if (dataEvent !== null) {
      handleSetForm(dataEvent)
    }
  // eslint-disable-next-line
  }, [dataEvent])

  const handleSetForm = (dataEvent) => {
    const dataDetail = {
      id: dataEvent.id,
      code: dataEvent.code,
      name: dataEvent.name,
      description: dataEvent.description,
      scope: 'internal',
      type: dataEvent.type,
      isScheduled: dataEvent.isScheduled ? 'true' : 'false',
      isRequired: dataEvent.isRequired ? 'true' : 'false',
      eventCategoryId: dataEvent.eventCategory.id,
      isActive: dataEvent.isActive ? 'true' : 'false',
      positionIds: []
    }
    const getImage = getImageFile(dataEvent.image)
    setFormData(dataDetail)
    setImagePoster(getImage)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const handlePosterEvent = (fileUpload) => {
    setValidationPoster(validationPosterEvent)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationPoster({
        poster: {
          isError: true,
          message: 'File gambar terlalu besar'
        }
      })
      setPosterFile(null)
      return
    }
    uploadPoster(fileUpload)
    setPosterFile(fileUpload)
  }

  const uploadPoster = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBase64(reader.target.result)
    }
  }

  const loadForm = () => {
    return (
      <Box>
        <Box mb='xl'>
          <Text py={12} ta='center' fw='600' fz={14}>Poster Pelatihan</Text>
          <Center h={300} mb={12}>
            <Avatar size={300} radius='md' src={imageBase64 || imagePoster} style={{ backgroundColor: '#ffff', border: '1px solid #b8b8b8' }}>
              <IconPhoto size="5rem" />
            </Avatar>
            <FileInput className={classes.formPoster} size={350} onChange={handlePosterEvent} accept="image/png,image/jpeg" />
          </Center>
          <Text ta="center" c="red" fz={12}>{validationPoster.poster.message}</Text>
          <Text mb={8} ta="center" c="yellow" fz={12} fw="bold">Klik ikon gambar untuk upload</Text>
          <Box>
            <Text ta="center" c="gray.5" fz={12}>Ukuran gambar maksimal 2 Mb</Text>
            <Text ta="center" c="gray.5" fz={12}>Tipe file yang diperbolehkan: jpg, png, jpeg</Text>
          </Box>
        </Box>
        <Box mb='md'>
          <TextInput
            name='name'
            value={formData.name}
            label='Nama Pelatihan'
            placeholder='Masukkan nama pelatihan'
            error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
            onChange={(val) => handleChangeForm('name', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
        <Box mb='md'>
          <TextInput
            name='code'
            value={formData.code}
            label='Kode Pelatihan'
            placeholder='Masukkan kode pelatihan'
            error={validationForm.code.isError ? `${validationForm.code.message}` : ''}
            onChange={(val) => handleChangeForm('code', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
        <Box mb='md'>
          <Select
            name='eventCategoryId'
            value={formData.eventCategoryId}
            label='Kategori Pelatihan'
            data={categoryList}
            error={validationForm.eventCategoryId.isError ? `${validationForm.eventCategoryId.message}` : ''}
            onChange={(val) => handleChangeForm('eventCategoryId', val)}
            withAsterisk
            size='xs'
            clearable={true}
          />
        </Box>
        <Box mb='md'>
          <MultiSelect
            name='positionIds'
            value={formData.positionIds}
            label='Kategori Jabatan'
            description='Apabila tidak memilih jabatan maka untuk semua jabatan'
            data={positionList}
            error={validationForm.positionIds.isError ? `${validationForm.positionIds.message}` : ''}
            onChange={(val) => handleChangeForm('positionIds', val)}
            withAsterisk
            size='xs'
            searchable
          />
        </Box>
        <Box mb='md'>
          <Textarea
            name='description'
            value={formData.description}
            label='Deskripsi Pelatihan'
            placeholder='Masukkan deskripsi pelatihan'
            error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
            onChange={(val) => handleChangeForm('description', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
        <Box mb='md'>
          <Select
            name='type'
            value={formData.type}
            label='Akses Pelatihan'
            data={defaultTypeEvent}
            error={validationForm.type.isError ? `${validationForm.type.message}` : ''}
            onChange={(val) => handleChangeForm('type', val)}
            withAsterisk
            size='xs'
            allowDeselect={false}
          />
        </Box>
        <Box mb='md'>
          <Select
            name='isScheduled'
            value={formData.isScheduled}
            label='Jadwal Pelatihan'
            data={defaultScheduledEvent}
            error={validationForm.isScheduled.isError ? `${validationForm.isScheduled.message}` : ''}
            onChange={(val) => handleChangeForm('isScheduled', val)}
            withAsterisk
            size='xs'
            allowDeselect={false}
          />
        </Box>
        <Box mb='md'>
          <Select
            name='isRequired'
            value={formData.isRequired}
            label='Tipe Pelatihan'
            data={defaultEvent}
            error={validationForm.isRequired.isError ? `${validationForm.isRequired.message}` : ''}
            onChange={(val) => handleChangeForm('isRequired', val)}
            withAsterisk
            size='xs'
            allowDeselect={false}
          />
        </Box>
        <Box mb='md'>
          <Input.Wrapper
            label='Status Pelatihan'
            size='xs'
          >
            <Switch 
              size="sm"
              onLabel="ON"
              offLabel="OFF"
              checked={formData.isActive === 'true' ? true : false}
              onChange={(val) => handleChangeForm('isActive', val.currentTarget.checked ? 'true' : 'false')}
            />
          </Input.Wrapper>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {loadingData ? <LoadingData /> : loadForm()}
      <Box mt={30}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={closeForm}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitEvent(formData)}>{dataEvent === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEvent