import React, { useEffect, useState } from 'react'
import LoadingPage from '../../../../components/ui/LoadingPage'
import { getDetailMemberEventSchedule, getListCurriculumEventSchedule } from '../../../../services/member-event-schedule'
import ProtectedRoute from '../../../../middleware/ProtectedRoute'
import LayoutDetailEvent from '../../../../components/pages/Member/Event/LayoutDetailEvent'
import { useParams } from 'react-router-dom'


const DetailMemberEvent = () => {
  const params = useParams()
  const scheduleId = params.schedule_id
  const [detail, setDetail] = useState({})
  const [curriculum, setCurriculum] = useState([])
  const [loading, setLoading] = useState(true)

  const handleGetDetailSchedule = async () => {
    setLoading(true)
    try {
      const response = await getDetailMemberEventSchedule(scheduleId)
      if (response) {
        const responseCurriculum = await handleGetListCurriculumSchedule(scheduleId)
        setDetail(response)
        setCurriculum(responseCurriculum.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetListCurriculumSchedule = async (idSchedule) => {
    try {
      const response = await getListCurriculumEventSchedule(scheduleId)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetDetailSchedule()
    // eslint-disable-next-line
  }, [])

  return (
    <ProtectedRoute>
      {loading ? <LoadingPage /> : <LayoutDetailEvent detailSchedule={detail} curriculum={curriculum} />}
    </ProtectedRoute>
    
  )
}

export default DetailMemberEvent