import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import LoadingPage  from '../components/ui/LoadingPage'

const ProtectedRoute = ({ children }) => {
  const { isLoading, isLogin } = useSelector(state => state.auth)
  const { pathname } = useLocation()
  const dataUser = useSelector(state => state.user.detailUser)
  if (isLoading) {
    return <LoadingPage />  
  }
  if (dataUser !== null) {
    if (dataUser.roleAlias === 'lms-admin' && pathname.includes('member')) {
      const routeAdmin = pathname.replace('/member', '')
      return <Navigate to={routeAdmin} replace />
    }
    if (dataUser.roleAlias === 'lms-user' && !pathname.includes('member')) {
      const route = pathname === '/' ? '/dashboard' : pathname
      const routeMember = '/member' + route
      return <Navigate to={routeMember} replace />
    }
  }
  if (!isLogin) {
    return <Navigate to='/login' replace />
  }
  return children ? children : <Outlet />
}

export default ProtectedRoute