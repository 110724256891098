import axios from "axios"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const http = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_API_URL}`
})

http.interceptors.request.use(
  (config) => {
    const sessionLogin = cookies.get('token_account_tandeem')
    if (sessionLogin) {
      config.headers['Authorization'] = `Bearer ${sessionLogin}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    const errorMessage = error.response.data.message
    if (errorMessage === 'Unauthorized') {
      cookies.remove('token_account_tandeem')
      cookies.remove('authData')
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

export default http