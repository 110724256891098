import http from '../plugins/axios'

export const getListMemberEventSchedule = async (params) => {
  try {
    const response = await http.get(`/in/user-schedules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailMemberEventSchedule = async (scheduleId) => {
  try {
    const response = await http.get(`/in/user-schedules/${scheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getListCurriculumEventSchedule = async (scheduleId) => {
  try {
    const response = await http.get(`/in/user-schedules/${scheduleId}/curriculum-schedules`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailCurriculumEventSchedule = async (scheduleId, curriculumId) => {
  try {
    const response = await http.get(`/in/user-schedules/${scheduleId}/curriculum-schedules/${curriculumId}`)
    return response.data
  } catch(error) {
    throw error
  }
}