import React from 'react'
import { Box, Text, Card, Grid, Image, Radio, CheckIcon, Flex, Button } from '@mantine/core'
import sistemDarkLogo from '../../../assets/img/laptop-dark.png'
import sistemLightLogo from '../../../assets/img/laptop-light.png'
import {useLocalStorage ,readLocalStorageValue } from '@mantine/hooks'

const Theme = ({ onClose }) => {
  const themeValue = readLocalStorageValue({ key: 'lms-app-theme' })
  const [, setThemeApp] = useLocalStorage({
    key: 'lms-app-theme',
    defaultValue: themeValue !== undefined ? themeValue : 'light'
  })
  
  const handleChangeTheme = () => {
    setThemeApp((current) => current === 'dark' ? 'light' : 'dark')
  }

  return (
    <Box>
      <Text fz={14} fw={500}>Ganti Tema Sistem</Text>
      <Box mt={20}>
        <Grid>
          <Grid.Col span={3}>
            <Card shadow="sm" radius="md" withBorder>
              <Box ta='center'>
                <Image
                  src={sistemLightLogo}
                  height={100}
                  alt="light-theme"
                  fit="contain"
                />
                <Box ta='center' mt={8}>
                  <Text>Terang</Text>
                  <Flex justify='center'>
                    <Radio icon={CheckIcon} checked={themeValue === 'light'} onChange={handleChangeTheme} />
                  </Flex>
                </Box>
              </Box>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card shadow="sm" radius="md" withBorder>
              <Box ta='center'>
                <Image
                  src={sistemDarkLogo}
                  height={100}
                  alt="dark-theme"
                  fit="contain"
                />
                <Box ta='center' mt={8}>
                  <Text>Gelap</Text>
                  <Flex justify='center'>
                    <Radio icon={CheckIcon} checked={themeValue === 'dark'} onChange={handleChangeTheme} />
                  </Flex>
                </Box>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box mt={50}>
        <Box ta='center'>
          <Button onClick={onClose}>Kembali</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Theme