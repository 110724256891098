import http from '../plugins/axios'

export const getListTask = async (params) => {
  try {
    const response = await http.get(`/tasks`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addTask = async (payload) => {
  try {
    const response = await http.post(`/tasks`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateTask = async (taskId, payload) => {
  try {
    const response = await http.patch(`/tasks/${taskId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteTask = async (taskId) => {
  try {
    const response = await http.delete(`/tasks/${taskId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailTask = async (taskId) => {
  try {
    const response = await http.get(`/tasks/${taskId}`)
    return response.data
  } catch(error) {
    throw error
  }
}