import React from 'react'
import classes from '../../../../pages/member/MemberLayout.module.css'
import { Box, Badge, Text, Image } from '@mantine/core'
import NoImage from  '../../../../assets/img/no-image.webp'
import { getImageFile } from '../../../../services/file'
import { useViewportSize } from '@mantine/hooks'


const InfoEvent = ({ detailEvent }) => {
  const { height } = useViewportSize()
  return (
    <Box mih={height / 1.25}>
      <Text className={classes.titleLayout} mb={6}>{detailEvent.schedule.event.name}</Text>
      <Badge color="green" variant="filled">{detailEvent.schedule.event.type}</Badge>
      <Box ta='center' my={40}>
        <Image 
          radius="md"
          h={350}
          w="auto"
          m="auto"
          fit="contain"
          src={detailEvent.schedule.event.image !== null ? getImageFile(detailEvent.schedule.event.image) : NoImage} 
        />
      </Box>
      <Box>
        <Box mb={16}>
          <Text fz="md" fw="bold">Deskripsi Pelatihan</Text>
          <Text fz="xs">{detailEvent.schedule.event.description ? detailEvent.schedule.event.description : '-'}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default InfoEvent