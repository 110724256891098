import React, { useState, useEffect } from 'react'
import { Box, TextInput, Textarea, Button, Group, Flex, Switch, Input } from '@mantine/core'
import { addEventCategory, updateEventCategory } from '../../../services/event-category'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  description: '',
  isActive: 'false'
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  }
}

const FormEventCategory = ({ dataCategory, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitCategory = async (formCategory) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formCategory.name,
      description: formCategory.description,
      isActive: formCategory.isActive === 'true' ? true : false
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataCategory === null) {
      methodFunction = addEventCategory(payload)
      titleMessageSuccess = 'Tambah Kategori Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan kategori baru'
      titleMessageError = 'Gagal Menambahkan Kategori'
    } else {
      methodFunction = updateEventCategory(formCategory.id, payload)
      titleMessageSuccess = 'Update Kategori Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate kategori'
      titleMessageError = 'Gagal Mengupdate Kategori'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (dataCategory !== null) {
      handleSetForm(dataCategory)
    }
  }, [dataCategory])

  const handleSetForm = (dataCategory) => {
    const dataDetail = {
      id: dataCategory.id,
      name: dataCategory.name,
      description: dataCategory.description,
      isActive: dataCategory.isActive ? 'true' : 'false'
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }
  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Kategori'
          placeholder='Masukkan nama kategori'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={(val) => handleChangeForm('description', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Input.Wrapper
          label='Status'
        >
          <Switch 
            size="xs"
            onLabel="ON"
            offLabel="OFF"
            checked={formData.isActive === 'true' ? true : false}
            onChange={(val) => handleChangeForm('isActive', val.currentTarget.checked ? 'true' : 'false')}
          />
        </Input.Wrapper>
        
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitCategory(formData)}>{dataCategory === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEventCategory