import { Image, Container, Title, Text, Button, SimpleGrid, Center } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import image from '../../../assets/img/not-found.svg'
import classes from './notFoundPage.module.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const { isLogin } = useSelector(state => state.auth)
  const dataUser = useSelector(state => state.user.detailUser)
  const { width, height } = useViewportSize()

  const goBackLayout = () => {
    let url = ''
    if (dataUser !== null) {
      if (dataUser.roleAlias === 'lms-admin') {
        url = '/dashboard'
        
      }
      if (dataUser.roleAlias === 'lms-user') {
        url = '/member/dashboard'
      }
    }
    if (!isLogin) {
      url = '/login'
    }
    return navigate(url, { replace: true })
  }
  return (
    <Center pos='relative' h={height} w={width}>
      <Container>
        <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
          <div>
            <Title className={classes.title}>Ada yang tidak beres...</Title>
            <Text c="dimmed" size="lg">
              Halaman yang Anda coba buka tidak ada. Anda mungkin salah mengetik alamat, atau
              telah dipindahkan ke URL lain. Jika menurut Anda ini adalah kesalahan, hubungi admin.
            </Text>
            <Button variant="outline" size="md" mt="xl" className={classes.control} onClick={() => goBackLayout()}>
              Kembali Ke halaman utama
            </Button>
          </div>
          <Image src={image} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
    </Center>
  )
}

export default NotFoundPage