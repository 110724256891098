// public route
import Login from './pages/authentication/Login'

// administrator route
import Dashboard from './pages/administrator/Dashboard'
import Users from './pages/administrator/Users'
import Setting from './pages/administrator/Setting'
import Office from './pages/administrator/Office'
import Position from './pages/administrator/Position'
import EventCategory from './pages/administrator/EventCategory'
import Event from './pages/administrator/Event'
import ScheduleEvent from './pages/administrator/Event/detail/ScheduleEvent'
import UserScheduleEvent from './pages/administrator/Event/detail/UserScheduleEvent'
import RecordingEvent from './pages/administrator/Event/detail/RecordingEvent'
import CurriculumEvent from './pages/administrator/Event/detail/CurriculumEvent'
import Module from './pages/administrator/Module'
import Task from './pages/administrator/Task'
import TaskQuest from './pages/administrator/Task/detail/TaskQuest'

// member route
import MemberDashboard from './pages/member/MemberDashboard'
import MemberEvent from './pages/member/MemberEvent'
import DetailMemberEvent from './pages/member/MemberEvent/detail/DetailMemberEvent'
import MemberEventAvailable from './pages/member/MemberEventAvailable'

// icon sidebar route
import { 
  IconGauge,
  IconUser,
  IconSettings,
  IconBuildingSkyscraper,
  IconBriefcase,
  IconCategoryPlus,
  IconNotebook,
  IconServer2,
  IconListCheck,
  IconUserCheck,
  IconCheckupList
} from "@tabler/icons-react"

// permission
import { MENU_PERMISSION, MENU_PERMISSION_MEMBER } from './plugins/helper'


const routes = [
  {
    name: 'login-page',
    route: '/login',
    hasChildren: false,
    component: <Login />
  },
  {
    name: 'dashboard',
    route: '/',
    hasChildren: false,
    component: <Dashboard />,
  },
]

const authRoutes = [
  {
    name: 'dashboard',
    route: '/dashboard',
    hasChildren: false,
    component: <Dashboard />,
    icon: <IconGauge stroke={1.5} size={18} />,
    type: 'route',
    group: null,
    permission: MENU_PERMISSION.dashboard
  },
  {
    name: 'program',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION.event
  },
  {
    name: 'kategori',
    route: '/event-category',
    hasChildren: false,
    component: <EventCategory />,
    icon: <IconCategoryPlus stroke={1.5} size={18} />,
    type: 'route',
    group: 'event',
    permission: MENU_PERMISSION.eventCategory
  },
  {
    name: 'pelatihan',
    route: '/event',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Event />
      },
      {
        name: 'curriculum-event',
        route: '/event/:id/curriculum',
        component: <CurriculumEvent />
      },
      {
        name: 'schedule-event',
        route: '/event/:id/schedule',
        component: <ScheduleEvent />
      },
      {
        name: 'recording-event',
        route: '/event/:id/schedule/:schedule_id/recording',
        component: <RecordingEvent />
      },
      {
        name: 'user-schedule-event',
        route: '/event/:id/schedule/:schedule_id/participant',
        component: <UserScheduleEvent />
      },
    ],
    icon: <IconNotebook stroke={1.5} size={18} />,
    type: 'route',
    group: 'event',
    permission: MENU_PERMISSION.event
  },
  {
    name: 'penyimpanan',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION.module
  },
  {
    name: 'modul materi',
    route: '/module',
    hasChildren: false,
    component: <Module />,
    icon: <IconServer2 stroke={1.5} size={18} />,
    type: 'route',
    group: 'module',
    permission: MENU_PERMISSION.module
  },
  {
    name: 'Soal',
    route: '/task',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Task />
      },
      {
        name: 'task-question',
        route: '/task/:id/question',
        component: <TaskQuest />
      }
    ],
    icon: <IconListCheck stroke={1.5} size={18} />,
    type: 'route',
    group: 'module',
    permission: MENU_PERMISSION.task
  },
  {
    name: 'lanjutan',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION.user
  },
  {
    name: 'pegawai',
    route: '/users',
    hasChildren: false,
    component: <Users />,
    icon: <IconUser stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.user
  },
  {
    name: 'pembimbing',
    route: '/tutor',
    hasChildren: false,
    component: <Users />,
    icon: <IconUserCheck stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.user
  },
  {
    name: 'jabatan',
    route: '/position',
    hasChildren: false,
    component: <Position />,
    icon: <IconBriefcase stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.position
  },
  {
    name: 'kantor',
    route: '/offices',
    hasChildren: false,
    component: <Office />,
    icon: <IconBuildingSkyscraper stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.office
  },
  {
    name: 'setelan',
    route: '/setting',
    hasChildren: false,
    component: <Setting />,
    icon: <IconSettings stroke={1.5} size={18} />,
    type: 'route',
    group: null,
    permission: MENU_PERMISSION.setting
  },
]

const memberRoutes = [
  {
    name: 'dashboard',
    route: '/member/dashboard',
    hasChildren: false,
    component: <MemberDashboard />,
    icon: <IconGauge stroke={1.5} size={18} />,
    type: 'route',
    group: null,
    permission: MENU_PERMISSION_MEMBER.dashboard
  },
  {
    name: 'pelatihan',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION_MEMBER.event
  },
  {
    name: 'tersedia',
    route: '/member/available-event',
    hasChildren: false,
    component: <MemberEventAvailable />,
    icon: <IconCheckupList stroke={1.5} size={18} />,
    type: 'route',
    group: 'event',
    permission: MENU_PERMISSION_MEMBER.event
  },
  {
    name: 'diikuti',
    route: '/member/event',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <MemberEvent />
      },
      {
        name: 'EventDetail',
        route: '/member/event/schedule/:schedule_id',
        component: <DetailMemberEvent />
      },
    ],
    component: <MemberEvent />,
    icon: <IconNotebook stroke={1.5} size={18} />,
    type: 'route',
    group: 'event',
    permission: MENU_PERMISSION_MEMBER.event
  },
  {
    name: 'lanjutan',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION_MEMBER.setting
  },
  {
    name: 'setelan',
    route: '/member/setting',
    hasChildren: false,
    component: <Setting />,
    icon: <IconSettings stroke={1.5} size={18} />,
    type: 'route',
    group: 'setting',
    permission: MENU_PERMISSION_MEMBER.setting
  },
]

export { routes, authRoutes, memberRoutes }