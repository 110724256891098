import React, { useState, useEffect } from 'react'
import { Box, Textarea, Button, Group, Flex, Input, Checkbox, CheckIcon, ActionIcon } from '@mantine/core'
import { addTaskQuestion, updateTaskQuestion, assignAnswerTaskQuestion } from '../../../services/task-question'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { IconTrash } from '@tabler/icons-react'
import { 
  ClassicEditor, 
  Bold, 
  Essentials, 
  Italic, 
  Mention, 
  Paragraph, 
  Undo, 
  Font, 
  Heading,
  Image,
  ImageCaption,
  ImageResizeEditing, 
  ImageResizeHandles,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageInline,
  ImageBlock,
  LinkImage,
  ImageUpload,
  ImageInsertViaUrl,
  List,
  MediaEmbed,
  Alignment
} from 'ckeditor5'

const defaultVal = {
  text: '',
  feedback: '',
}

const formValidation = {
  text: {
    isError: false,
    message: ''
  },
  feedback: {
    isError: false,
    message: ''
  },
}

const defaultAnswer = [
  {
    id: null,
    text: '',
    isCorrect: false,
  }
]

const validateAnswer = {
  answer: {
    isError: false,
    message: ''
  },
}

const FormTaskQuest = ({ dataQuest, closeForm, reloadList }) => {
  const params = useParams()
  const taskId = params.id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [answerList, setAnswerList] = useState(defaultAnswer)
  const [answerValidation, setAnswerValidation] = useState(validateAnswer)
  const [isLoaded, setIsLoaded] = useState(false)

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file
        const base64 = await toBase64(file)
        return {
          default: base64
        }
      },
    }
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  const configEditor = {
    extraPlugins: [ uploadPlugin ],
    plugins:[
      Bold,
      Essentials,
      Italic,
      Mention,
      Paragraph,
      Undo,
      Font,
      Heading,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageInline,
      ImageBlock,
      LinkImage,
      ImageUpload,
      ImageResizeEditing, 
      ImageResizeHandles,
      ImageInsertViaUrl,
      List,
      MediaEmbed,
      Alignment
    ],
    alignment: {
      options: [ 'left', 'right' ]
    },
    fontSize: {
      options: [
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22
      ],
      supportAllValues: true
    },
    image: {
      resizeUnit: "%",
			resizeOptions: [
        {
            name: 'resizeImage:original',
            value: null,
            label: 'Original'
        },
        {
            name: 'resizeImage:custom',
            label: 'Custom',
            value: 'custom'
        },
        {
            name: 'resizeImage:40',
            value: '40',
            label: '40%'
        },
        {
            name: 'resizeImage:60',
            value: '60',
            label: '60%'
        }
      ],
      toolbar: [
        'resizeImage',
        '|',
        'imageStyle:alignBlockLeft',
        'imageStyle:alignBlockRight',
        '|',
        'imageStyle:alignCenter',
        '|',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'linkImage'
      ],
      insert: {
          integrations: [ 'upload', 'url' ]
      }
    },
    list: {
      properties: {
          styles: true,
          startIndex: true,
          reversed: true
      }
    },
    toolbar: {
      items: [
        'undo', 'redo',
        '|',
        'heading',
        '|',
        'fontSize', 'Bold', 'Italic', 'alignment',
        '|',
        'link', 'insertImage',
        '|',
        'mediaEmbed',
        '|',
        'bulletedList', 'numberedList',
        '|',
      ],
      shouldNotGroupWhenFull: true
    },
    initialData: `${formData.text}`
  }

  const handleValidateAnswer = () => {
    let validate = false
    for (let i = 0; i < answerList.length; i++) {
      if (answerList.length < 1) {
        validate = true
      } else if (answerList[i].text === '') {
        validate = true
      } else {
        validate = false
      }
    }
    return validate
  }

  const submitQuest = async (formQuest) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    setAnswerValidation(validateAnswer)
    const payload = {
      text: formQuest.text,
      feedback: formQuest.feedback,
      taskId: taskId
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    const errorAnswer = handleValidateAnswer()
    if (errorAnswer) {
      setLoadingForm(false)
      setAnswerValidation((old) => ({
        ...old,
        'answer': {
          isError: true,
          message: 'Silahkan cek form jawaban anda'
        }
      }))
      return
    }
    if (dataQuest === null) {
      methodFunction = addTaskQuestion(payload)
      titleMessageSuccess = 'Tambah Soal Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan soal kuesioner baru'
      titleMessageError = 'Gagal Menambahkan Soal'
    } else {
      methodFunction = updateTaskQuestion(formQuest.id, payload)
      titleMessageSuccess = 'Update Soal Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate soal kuesioner'
      titleMessageError = 'Gagal Mengupdate Soal'
    }
    try {
      const response = await methodFunction
      if (response) {
        const submitAnswer = await handleAnswerTaskQuestion(formQuest.id || response.id)
        if (submitAnswer) {
          closeForm()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleAnswerTaskQuestion = async(questId) => {
    const payload = {
      taskAnswers: answerList
    }
    try {
      const response = await assignAnswerTaskQuestion(questId, payload)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (dataQuest !== null) {
      handleSetForm(dataQuest)
    }
    setIsLoaded(true)
    // eslint-disable-next-line
  }, [dataQuest])

  const handleSetForm = async (dataQuest) => {
    let remapAnswer = []
    const dataDetail = {
      id: dataQuest.id,
      text: dataQuest.text,
      feedback: dataQuest.feedback,
    }
    if (dataQuest.taskAnswers.length > 0) {
      remapAnswer = dataQuest.taskAnswers.map((val) => {
        const remap = {
          id: val.id,
          text: val.text,
          isCorrect: val.isCorrect
        }
        return remap
      })
    } else {
      remapAnswer = []
    }
    setFormData(dataDetail)
    setAnswerList(remapAnswer)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const addAnswer = () => {
    const payload = {
      id: null,
      text: '',
      isCorrect: false,
    }
    setAnswerList(oldVal => [...oldVal, payload])
  }

  

  const mappingDataAnswer = (dataAnswer) => {

    const handleChangeCorrectAnswer = (valIndex, value) => {
      setAnswerList(oldVal => [...oldVal].map((val, index) => index === valIndex ? ({ ...val, isCorrect: true }) : ({ ...val, isCorrect: false }) ))
    }

    const handleChangeFormAnswer = (valIndex, value) => {
      setAnswerList(oldVal => [...oldVal].map((val, index) => index === valIndex ? ({ ...val, text: value }) : val ))
    }

    const removeAnswer = (valIndex) => {
      const data = [...answerList]
      const dataIndex = data.indexOf(valIndex)
      if (valIndex > -1) {
        data.splice(dataIndex, 1)
        setAnswerList(data)
      }
    }

    const remapAnswer = answerList.map((val, index) => {
      return (
        <Group gap='sm' key={index} wrap="nowrap" mb={12}>
          <Checkbox radius='xl' color='green' icon={CheckIcon} checked={answerList[index].isCorrect}  onChange={(val) => handleChangeCorrectAnswer(index, val.target.checked)} />
          <Input size='xs' value={val.text} w='768' onChange={(val) => handleChangeFormAnswer(index, val.target.value)} />
          <ActionIcon variant="transparent" color="red" onClick={() => removeAnswer(index)}>
            <IconTrash stroke={1.5} size={20} />
          </ActionIcon>
        </Group>
      )
    })
    return remapAnswer
  }

  const initCKeditor = () => {
    return (
      <CKEditor
          editor={ClassicEditor}
          config={configEditor}
          data={formData.text}
          onReady={(editor) => {
            editor.execute('fontSize', { value: '13px' })
            editor.editing.view.change((writer) => {
              writer.setStyle(
                'height',
                '450px',
                editor.editing.view.document.getRoot()
              )
            })
          }}
          onChange={( event, editor ) => {
            const valueEditor = editor.getData()
            handleChangeForm('text', valueEditor)
          }}
        />
    )
  }

  
  return (
    <Box>
      <Box mb='md'>
        <Input.Wrapper 
          label="Pertanyaan"
          withAsterisk
          error={validationForm.text.isError ? `${validationForm.text.message}` : ''}
        >
          {isLoaded ? initCKeditor() : ''}
        </Input.Wrapper>
      </Box>
      <Box mb='md'>
        <Input.Wrapper
          label="Jawaban"
          withAsterisk
          error={answerValidation.answer.isError ? `${answerValidation.answer.message}` : ''}
        >
          <Box>
            <Flex justify='flex-end'>
              <Button size='xs' variant="filled" onClick={() => addAnswer()}>Tambah Jawaban</Button>
            </Flex>
            <Box mt={20}>
              {mappingDataAnswer(answerList)}
            </Box>
          </Box>
        </Input.Wrapper>
      </Box>
      <Box mb='md'>
        <Textarea
          name='feedback'
          value={formData.feedback}
          label='Feedback Soal'
          placeholder='Masukkan Feedback Soal'
          error={validationForm.feedback.isError ? `${validationForm.feedback.message}` : ''}
          onChange={(val) => handleChangeForm('feedback', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>

      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={closeForm}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitQuest(formData)}>{dataQuest === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormTaskQuest