import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, FileInput } from '@mantine/core'
import { addSchedule, updateSchedule, uploadRundownSchedule } from '../../../services/event-schedule'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useParams } from 'react-router-dom'
import { IconFileCv } from '@tabler/icons-react'
import { getImageFile } from '../../../services/file'
import { getFileFromUrl } from '../../../plugins/getFileUrl'

const defaultVal = {
  name: '',
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  }
}

const FormEventSchedule = ({ dataSchedule, onCloseModal, reloadList }) => {
  const params = useParams()
  const eventId = params.id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [fileRundown, setFileRundown] = useState(null)
  const [attachFile, setAttachFile] = useState(false)

  const submitSchedule = async (formSchedule) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formSchedule.name,
      eventId: eventId
    }
    delete formData.isActive
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataSchedule === null) {
      methodFunction = addSchedule(payload)
      titleMessageSuccess = 'Tambah Jadwal Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan jadwal pelatihan baru'
      titleMessageError = 'Gagal Menambahkan Jadwal Pelatihan'
    } else {
      methodFunction = updateSchedule(formSchedule.id, payload)
      titleMessageSuccess = 'Update Jadwal Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate jadwal pelatihan'
      titleMessageError = 'Gagal Mengupdate Jadwal Pelatihan'
    }
    try {
      const response = await methodFunction
      if (response) {
        if (attachFile) {
          const uploadRundown = await handleUploadRundownSchedule(response.id || formSchedule.id)
          if (uploadRundown.affected === 1) {
            onCloseModal()
            reloadList()
            notificationSuccess(titleMessageSuccess, captionMessageSuccess)
            setLoadingForm(false)
          }
        } else {
          onCloseModal()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUploadRundownSchedule = async(scheduleId) => {
    const form = new FormData()
    form.append('file', fileRundown)
    try {
      const response = await uploadRundownSchedule(scheduleId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (dataSchedule !== null) {
      handleSetForm(dataSchedule)
    }
    // eslint-disable-next-line
  }, [dataSchedule])

  const handleSetForm = async (dataSchedule) => {
    const dataDetail = {
      id: dataSchedule.id,
      name: dataSchedule.name
    }
    setFileRundown(dataSchedule.rundown !== null ? await getFileFromUrl(getImageFile(dataSchedule.rundown), dataSchedule.rundown) : null)
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Jadwal Pelatihan'
          placeholder='Masukkan nama jadwal pelatihan'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <FileInput
          leftSection={<IconFileCv size='1.2rem' stroke={1.5} />}
          value={fileRundown}
          onChange={(val) => [setFileRundown(val), setAttachFile(true)]}
          label="Lampiran File Rundown"
          placeholder="File Rundown"
          size='xs'
          clearable
          accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitSchedule(formData)}>{dataSchedule === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEventSchedule