import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getAuthPermission } from '../services/authorization'

export const getPermissionLogin = createAsyncThunk('permission/getPermissionLogin', async () => {
  const response = await getAuthPermission()
  return response
})

const permissionLogin = createSlice({
  name: 'permission',
  initialState: {
    isLoading: true,
    access: null
  },
  reducers: {
    permissionState: (state, action) => {
      state.isLoading = action.payload.isLoading
      state.access = action.payload.access
    }
  },
  extraReducers: {
    [getPermissionLogin.pending]: (state) => {
      state.isLoading = true
    },
    [getPermissionLogin.fulfilled]: (state, action) => {
      state.isLoading = false
      state.access = action.payload
      
    },
    [getPermissionLogin.rejected]: (state) => {
      state.isLoading = false
      state.access = null
    }
  }
})

export const { permissionState } = permissionLogin.actions
export default permissionLogin.reducer