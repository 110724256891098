import http from '../plugins/axios'

export const getListTaskQuestion = async (params) => {
  try {
    const response = await http.get(`/task-questions`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addTaskQuestion = async (payload) => {
  try {
    const response = await http.post(`/task-questions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateTaskQuestion = async (taskQuestId, payload) => {
  try {
    const response = await http.patch(`/task-questions/${taskQuestId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteTaskQuestion = async (taskQuestId) => {
  try {
    const response = await http.delete(`/task-questions/${taskQuestId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignAnswerTaskQuestion = async (taskQuestId, payload) => {
  try {
    const response = await http.patch(`/task-questions/${taskQuestId}/answers`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}