import http from '../plugins/axios'


export const getListUsers = async (params) => {
  try {
    const response = await http.get(`/users`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUser = async (payload) => {
  try {
    const response = await http.patch(`auth/user`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUserPassword = async (payload) => {
  try {
    const response = await http.patch(`auth/password`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const syncHrisUser = async () => {
  try {
    const response = await http.patch(`/users/sync`)
    return response.data
  } catch(error) {
    throw error
  }
}