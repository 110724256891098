import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from 'universal-cookie'
import http from '../plugins/axios'
import { verifyToken } from '../services/authorization'

const cookies = new Cookies()

export const getCredential = createAsyncThunk('auth/getCredential', async () => {
  const session = cookies.get('token_account_tandeem')
  let responseCredential = ''
  if (session !== undefined) {
    const payload = {
      token: session
    }
    const verify = await verifyToken(payload)
    if (verify) {
      responseCredential = verify.message === 'Token Valid' ? session : 'invalid-token'
    }
  } else {
    responseCredential = 'invalid-token'
  }
  return responseCredential
})

const authData = createSlice({
  name: 'auth',
  initialState: {
    authorization: null,
    isLogin: false,
    isLoading: true
  },
  reducers: {
    update: (state, action) => {
      state.authorization = action.payload.authorization
      state.isLogin = action.payload.isLogin
      state.isLoading = action.payload.isLoading
    }
  },
  extraReducers: {
    [getCredential.pending]: (state) => {
      state.isLoading = true
    },
    [getCredential.fulfilled]: (state, action) => {
      if (action.payload !== 'invalid-token') {
        http.defaults.headers.common['Authorization'] = `Bearer ${action.payload}`  
        state.isLogin = true
        state.authorization = action.payload
        state.isLoading = false
      } else {
        cookies.remove('token_account_tandeem')
        state.isLogin = false
        state.authorization = null
        state.isLoading = false
      }
    },
    [getCredential.rejected]: (state) => {
      state.isLoading = false
      state.isLogin = false
    }
  }
})

export const { update } = authData.actions
export default authData.reducer