import http from '../plugins/axios'

export const getListEvent = async (params) => {
  try {
    const response = await http.get(`/events`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addEvent = async (payload) => {
  try {
    const response = await http.post(`/events`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateEvent = async (eventId, payload) => {
  try {
    const response = await http.patch(`/events/${eventId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteEvent = async (eventId) => {
  try {
    const response = await http.delete(`/events/${eventId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailEvent = async (eventId) => {
  try {
    const response = await http.get(`/events/${eventId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignEventByPosition = async (payload) => {
  try {
    const response = await http.patch(`/position-events/by-event`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getPositionByEvent = async (eventId) => {
  const params = {
    event: eventId
  }
  try {
    const response = await http.get(`/position-events`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadPosterEvent = async (eventId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/events/${eventId}/image`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}