import React from 'react'
import { Table, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconInfoCircle } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionDelete = () => {
    const permissionSchedule = permissionState['schedule']
    const deletePermission = permissionSchedule.find(val => val.alias === 'schedule.delete')
    if (deletePermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={14} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={11}
        >
          Hapus
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={8} pt={6} variant='transparent' size='xs'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {/* {permissionState !== null ? permissionUpdate() : ''} */}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TableEventUserSchedule = ({ label, data, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)


  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    const mappingMenu = () => {
      const permissionSchedule = permissionState['schedule']
      const updatedPermission = permissionSchedule.find(val => val.alias === 'schedule.update')
      const deletedPermission = permissionSchedule.find(val => val.alias === 'schedule.delete')
      if (updatedPermission === undefined && deletedPermission === undefined) {
        return (
          <Tooltip label='Anda tidak diizinkan untuk mengakses menu ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs'>
              <IconInfoCircle stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        )
      } else {
        return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
      }
    }

    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.user.fullName}</Table.Td>
        <Table.Td>{val.user.email}</Table.Td>
        <Table.Td>{val.completion ? 'Lulus' : 'Belum Lulus'}</Table.Td>
        <Table.Td>{permissionState !== null ? mappingMenu() : ''}</Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableEventUserSchedule