import http from '../plugins/axios'

export const addCurriculumProgress = async (payload) => {
  try {
    const response = await http.post(`/in/curriculum-progresses`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCurriculumProgress = async (curriculumId, payload) => {
  try {
    const response = await http.patch(`/in/curriculum-progresses${curriculumId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}