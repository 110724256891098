import React, { useState, useEffect } from 'react'
import { Box, Button, Group, MultiSelect, Flex } from '@mantine/core'
import { addUserSchedule } from '../../../services/event-user-schedule'
import { getListUsers } from '../../../services/users'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import LoadingData from '../../ui/LoadingData'
import { useParams } from 'react-router-dom'

const defaultVal = {
  userIds: []
}

const formValidation = {
  userIds: {
    isError: false,
    message: ''
  }
}

const FormEventUserSchedule = ({ onCloseModal, reloadList, typeParticipant }) => {
  const params = useParams()
  const scheduleId = params.schedule_id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [userList, setUserList] = useState([])

  const handleGetUserList = async () => {
    setLoadingData(true)
    let params = {}
    if (typeParticipant === 'tutor') {
      params = {
        isTutor: 1,
        notInSchedule: scheduleId

      }
    } else {
      params = {
        isUser: 1,
        notInSchedule: scheduleId
      }
    }
    try {
      const response = await getListUsers(params)
      const dataUser = response.data
      const remapUser = dataUser.map((val) => {
        const mappingValue = {
          value: val.id,
          label: `${val.fullName} ${val.position !== null ? `( ${val.position.name} )` : ''}`
        }
        return mappingValue
      })
      setUserList(remapUser)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }

  const submitUserSchedule = async (formUser) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    if (formData.userIds.length === 0) {
      setValidationForm((old) => ({
        ...old,
        'userIds': {
          isError: true,
          message: 'Form tidak boleh kosong'
        }
      }))
      setLoadingForm(false)
      return
    }
    const payload = {
      scheduleId: scheduleId,
      userIds: formUser.userIds,
      isTutor: typeParticipant === 'tutor' ? true : false
    }
    try {
      const response = await addUserSchedule(payload)
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(`Penambahan Data ${typeParticipant === 'tutor' ? 'Pembimbing' : 'Peserta'} Berhasil`, `Anda telah menambahkan data ${typeParticipant === 'tutor' ? 'Pembimbing' : 'Peserta'} Baru`)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      const captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(`Gagal Menambahkan ${typeParticipant === 'tutor' ? 'Pembimbing' : 'Peserta'}`, captionMessageError)
    }
  }

  useEffect(() => {
    if (typeParticipant !== null) {
      handleGetUserList()
    }
  // eslint-disable-next-line
  }, [typeParticipant])

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const loadForm = () => {
    return (
      <Box>
        <Box mb='md'>
          <MultiSelect
            name='userIds'
            value={formData.userIds}
            label={`Pilih Data ${typeParticipant === 'tutor' ? 'Pembimbing' : 'Peserta'}`}
            data={userList}
            error={validationForm.userIds.isError ? `${validationForm.userIds.message}` : ''}
            onChange={(val) => handleChangeForm('userIds', val)}
            withAsterisk
            size='xs'
            searchable
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {loadingData ? <LoadingData /> : loadForm()}
      <Box mt={30}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitUserSchedule(formData)}>Tambah</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEventUserSchedule 