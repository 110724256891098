import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select, Button, Modal, Center, Loader } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getDetailEvent } from '../../../../../services/event'
import { getListSchedule, deleteSchedule } from '../../../../../services/event-schedule'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableEventSchedule from '../../../../../components/pages/EventSchedule/TableEventSchedule'
import FormEventSchedule from '../../../../../components/pages/EventSchedule/FormEventSchedule'
import { modalDeleteData } from '../../../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { updateRoute } from '../../../../../store/dataRoute'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Jadwal Pelatihan',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]



const ScheduleEvent = () => {
  const navigate = useNavigate()
  const params = useParams()
  const eventId = params.id
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    event: eventId,
    orderBy: 'name',
    order: 'asc'
  }
  const permissionState = useSelector(state => state.permission.access)
  const [parameter, setParameter] = useState(defaultParameter)
  const [detailEvent, setDetailEvent] = useState(null)
  const [scheduleList, setScheduleList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan',
      route: '/event'
    },
    {
      label: 'Jadwal',
      route: null
    },
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: parameter.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: parameter.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetEventScheduleList = async () => {
    setLoadingTable(true)
    if (detailEvent === null) {
      setLoading(true)
    }
    try {
      const response = await getListSchedule(parameter)
      setScheduleList(response.data)
      setCount(response.count)
      if (detailEvent === null) {
        handleGetDetailEvent(eventId)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleGetDetailEvent = async (idEvent) => {
    try {
      const response = await getDetailEvent(idEvent)
      if (response) {
        setDetailEvent(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetEventScheduleList()
    // eslint-disable-next-line
  }, [parameter])

  

  const handleChangePage = (val) => {
    setParameter((oldVal) => ({ ...oldVal, [oldVal.skip]: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParameter((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    recording: (val) => {
      navigate(`/event/${eventId}/schedule/${val.id}/recording`)
    },
    participant: (val) => {
      navigate(`/event/${eventId}/schedule/${val.id}/participant`)
    },
    delete: (val) => {
      modalDeleteData('jadwal pelatihan', val.id, val.name, deleteSchedule, handleGetEventScheduleList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionSchedule = permissionState['schedule']
    const createdPermission = permissionSchedule.find(val => val.alias === 'schedule.create')
    if (createdPermission !== undefined && detailEvent.isScheduled) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah Jadwal</Button>
      )
    }
  }

  const loadData = () => {
    return (
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Jadwal Pelatihan "{detailEvent.name}"</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Jadwal. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={parameter.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={parameter.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loadingTable ? <TableSkeleton total={4} /> : <TableEventSchedule label={labelTable} data={scheduleList} actionMethod={handleAction} /> }
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / parameter.take) || 0} />
        </Flex>
      </Box>
    )
  }

  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData /> : loadData()}
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Jadwal Pelatihan</Text>} withCloseButton={false}>
        <FormEventSchedule dataSchedule={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetEventScheduleList} />
      </Modal>
    </AuthLayout>
  )
}

export default ScheduleEvent