import React, { useState, useEffect } from 'react'
import { Box, Text, Flex, Pagination, TextInput, Select } from '@mantine/core'
import AuthLayout from '../../../layout/AuthLayout'
import { getListUsers } from '../../../services/users'
import classes from '../AdminLayout.module.css'
import TableUsers from '../../../components/pages/Users/TableUsers'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'

// const defaultParameter = {
//   skip: 0,
//   take: 10,
//   search: '',
//   orderBy: 'fullName',
//   order: 'asc',
// }

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'fullName',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Email',
    width: 'auto'
  },
  {
    label: 'Jabatan',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 'auto'
  }
]

const Users = () => {
  const { pathname } = useLocation()
  let defaultParameter = {}
  if (pathname === '/users') {
    defaultParameter = {
      skip: 0,
      take: 10,
      search: '',
      orderBy: 'fullName',
      order: 'asc',
      isUser: 1
    }
  } else if (pathname === '/tutor') {
    defaultParameter = {
      skip: 0,
      take: 10,
      search: '',
      orderBy: 'fullName',
      order: 'asc',
      isTutor: 1
    }
  }
  const [ params, setParams ] = useState(defaultParameter)
  const [ userList, setUserList ] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: pathname === '/users' ? 'Pegawai' : 'Pembimbing',
      route: null
    }
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetUserList = async () => {
    setLoading(true)
    try {
      const response = await getListUsers(params)
      setUserList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pathname === '/users') {
      const paramsUser = {
        skip: 0,
        take: 10,
        search: '',
        orderBy: 'fullName',
        order: 'asc',
        isUser: 1
      }
      setParams(paramsUser)
    } else {
      const paramsTutor = {
        skip: 0,
        take: 10,
        search: '',
        orderBy: 'fullName',
        order: 'asc',
        isTutor: 1
      }
      setParams(paramsTutor)
    }
  }, [pathname])

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch, pathname])

  useEffect(() => {
    handleGetUserList()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (val) => {
    setParams((oldVal) => ({ ...oldVal, [oldVal.skip]: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen {pathname === '/users' ? 'Pegawai' : 'Pembimbing'}</Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari User. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={6} /> : <TableUsers label={labelTable} data={userList} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default Users