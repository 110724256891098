import http from '../plugins/axios'

export const getListUserSchedule = async (params) => {
  try {
    const response = await http.get(`/user-schedules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addUserSchedule = async (payload) => {
  try {
    const response = await http.post(`/user-schedules/batch`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const generateUserSchedule = async (payload) => {
  try {
    const response = await http.patch(`/user-schedules/assign-all`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteUserSchedule = async (userScheduleId) => {
  try {
    const response = await http.delete(`/user-schedules/${userScheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}