import http from '../plugins/axios'

export const getListEventCategory = async (params) => {
  try {
    const response = await http.get(`/event-categories`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addEventCategory = async (payload) => {
  try {
    const response = await http.post(`/event-categories`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateEventCategory = async (categoryId, payload) => {
  try {
    const response = await http.patch(`/event-categories/${categoryId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteEventCategory = async (categoryId) => {
  try {
    const response = await http.delete(`/event-categories/${categoryId}`)
    return response.data
  } catch(error) {
    throw error
  }
}