import http from '../plugins/axios'

export const login = async (payload) => {
  try {
    const response = await http.post(`/auth/login`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const verifyToken = async (payload) => {
  try {
    const response = await http.post(`/auth/check-token`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const detailAuth = async () => {
  try {
    const response = await http.get(`/auth`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getAuthPermission = async () => {
  try {
    const response = await http.get(`/auth/permissions`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const logout = async () => {
  try {
    const response = await http.post(`/auth/logout`)
    return response.data
  } catch(error) {
    throw error
  }
}