import React from 'react'
import { Table, Badge } from '@mantine/core'

const TableUsers = ({ label, data, actionMethod }) => {


  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.fullName}</Table.Td>
        <Table.Td>{val.email}</Table.Td>
        <Table.Td>{val.position !== null ? val.position.name : '-'}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={val.isActive ? 'green' : 'red'}>{val.isActive ? 'Active' : 'Non Active'}</Badge></Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableUsers